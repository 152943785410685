import React from "react";
import { useState, useEffect } from "react";
import {
  createBrowserRouter,
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { Helmet } from "react-helmet"; //
import { Desktop } from "./src/screens/DesktopMain/index";
import { Ipad } from "./src/screens/IpadMain/index";
import { Mobile } from "./src/screens/MobileMain/index";
import { DesktopMenu } from "./src/screens/DesktopMenuMain";
import { IpadMenu } from "./src/screens/IpadMenuMain";
import { MobileMenu } from "./src/screens/MobileMenuMain";
import useMediaQuery from "./hooks/useMediaQuery";
import { HCD } from "./src/screens/HostedCheckout/HostedCheckout-D";
import { HCI } from "./src/screens/HostedCheckout/HostedCheckout-I";
import { HCM } from "./src/screens/HostedCheckout/HostedCheckout-M";
import { LoyaltyDesktop } from "./src/screens/Loyalty/Loyalty-D";
import { LoyaltyIpad } from "./src/screens/Loyalty/Loyalty-I";
import { LoyaltyMobile } from "./src/screens/Loyalty/Loyalty-M";
import { CBD } from "./src/screens/CashBack/CashBack-D";
import { CBI } from "./src/screens/CashBack/CashBack-I";
import { CBM } from "./src/screens/CashBack/CashBack-M";
import { AUD } from "./src/screens/AboutUs/AboutUs-D";
import { AUI } from "./src/screens/AboutUs/AboutUs-I";
import { AUM } from "./src/screens/AboutUs/AboutUs-M";
import { LegalD } from "./src/screens/Legal/Legal-D";
import { LegalI } from "./src/screens/Legal/Legal-I";
import { LegalM } from "./src/screens/Legal/Legal-M";
import { ErrorPageDesktop } from "./src/screens/404Page/404Page-D";
import { ErrorPageIpad } from "./src/screens/404Page/404Page-I";
import { ErrorPageMobile } from "./src/screens/404Page/404Page-M";
import { CUD } from "./src/screens/ContactUs/ContactUs-D";
import { CUI } from "./src/screens/ContactUs/ContactUs-I";
import { CUM } from "./src/screens/ContactUs/ContactUs-M";
import { CheckoutBD } from "./src/screens/CheckoutButtons/CheckoutButtons-D";
import { CheckoutBI } from "./src/screens/CheckoutButtons/CheckoutButtons-I";
import { CheckoutBM } from "./src/screens/CheckoutButtons/CheckoutButtons-M";
import { PaymentLD } from "./src/screens/PaymentLinks/PaymentLinks-D";
import { PaymentLI } from "./src/screens/PaymentLinks/PaymentLinks-I";
import { PaymentLM } from "./src/screens/PaymentLinks/PaymentLinks-M";
import LoadingScreen from "./src/Loader/LoadingScreen";
import ConsentComponent from "./src/cookies/CookieConsent";
import { PWCD } from "./src/screens/PayWithCrypto/PayWithCrypto-D";
import { PWCI } from "./src/screens/PayWithCrypto/PayWithCrypto-I";
import { PWCM } from "./src/screens/PayWithCrypto/PayWithCrypto-M";
import { NewsD } from "./src/screens/News/News-D";
import { NewsI } from "./src/screens/News/News-I/index.jsx";
import { NewsM } from "./src/screens/News/News-M/index.jsx";
import { ReferralD } from "./src/screens/Referral/Referral-D";
import { ReferralI } from "./src/screens/Referral/Referral-I";
import { ReferralM } from "./src/screens/Referral/Referral-M";
import { Article1 } from "./src/screens/Articles/Article-1/Article1.jsx";
import { Article2 } from "./src/screens/Articles/Article-2/Article2.jsx";
import { Article3 } from "./src/screens/Articles/Article-3/Article3.jsx";
import { Article4 } from "./src/screens/Articles/Article-4/Article4.jsx";
import { Article5 } from "./src/screens/Articles/Article-5/Article5.jsx";
import { Article6 } from "./src/screens/Articles/Article-6/Article6.jsx";
import { Article7 } from "./src/screens/Articles/Article-7/Article7.jsx";
import { Article8 } from "./src/screens/Articles/Article-8/Article8.jsx";
import { Article9 } from "./src/screens/Articles/Article-9/Article9.jsx";
import { Article10 } from "./src/screens/Articles/Article-10/Article10.jsx";

export const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(loadingTimer);
  }, []);

  const isDesktop = useMediaQuery("(min-width: 1280px)");
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1279px)");
  const isMobile = useMediaQuery("(max-width: 767px)");

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <ConsentComponent />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Helmet>
                  <title>
                    Xion Global - Makin' Crypto Payments & Rewards Easy
                  </title>
                  <meta
                    name="description"
                    content="Xion Global is a multi-chain web3 payments provider, offering a powerful suite of APIs to accept, send and reward payments globally."
                  />
                  <meta
                    name="keywords"
                    content="xion global, web3 payments infrastructure, decentralised payments, multi-chain, one click payments, web3 apis, cross chain, web3 subsciptions, web3 gaming payments, web3 sales funnels, APIs"
                  />
                </Helmet>
                {isDesktop && <Desktop />}
                {isIpad && <Ipad />}
                {isMobile && <Mobile />}
              </>
            }
          />

          <Route
            path="/menu"
            element={
              <>
                {isDesktop && <DesktopMenu />}
                {isIpad && <IpadMenu />}
                {isMobile && <MobileMenu />}
              </>
            }
          />

          <Route
            path="/hosted-checkout"
            element={
              <>
                <Helmet>
                  <title>Xion Global - Web3 Hosted-Checkout</title>
                  <meta
                    name="description"
                    content="Accept, send and reward Web3 payments easily and hassle FREE with a global Web3 hosted checkout."
                  />
                  <meta
                    name="keywords"
                    content="web3 hosted checkout, multichain payments, payments links, checkouts buttons, one click payments, xion global hosted checkout, web3 checkout"
                  />
                </Helmet>
                {isDesktop && <HCD />}
                {isIpad && <HCI />}
                {isMobile && <HCM />}
              </>
            }
          />

          <Route
            path="/loyalty"
            element={
              <>
                <Helmet>
                  <title>Xion Global - Web3 Loyalty Program</title>
                  <meta
                    name="description"
                    content="Empower customers with a Web3 loyalty program. The longer they stay, the more they save, enhancing customer retention and satisfaction."
                  />
                  <meta
                    name="keywords"
                    content="web3 loyalty program, decentralized loyalty programs, web3 customer retention, web3 customer savings, xion global loyalty program, crypto loyalty program"
                  />
                </Helmet>
                {isDesktop && <LoyaltyDesktop />}
                {isIpad && <LoyaltyIpad />}
                {isMobile && <LoyaltyMobile />}
              </>
            }
          />

          <Route
            path="/cashback-rewards"
            element={
              <>
                <Helmet>
                  <title>Xion Global - Web3 Cashback Rewards</title>
                  <meta
                    name="description"
                    content="Boost customer satisfaction with instant cashback rewards on every purchase, enhancing the shopping experience and businesses brand value."
                  />
                  <meta
                    name="keywords"
                    content="web3 cashback rewards, decentralized cashback, customer cashback rewards, crypto rewards, xion global cashback, cashback on payments, crypto cashback rewards"
                  />
                </Helmet>
                {isDesktop && <CBD />}
                {isIpad && <CBI />}
                {isMobile && <CBM />}
              </>
            }
          />

          <Route
            path="/checkout-buttons"
            element={
              <>
                <Helmet>
                  <title>Xion Global - Web3 Checkout Buttons</title>
                  <meta
                    name="description"
                    content="Easily embed Web3 checkout buttons on your online store with Xion Global. Customize the look and feel to match your brand. Start today!"
                  />
                  <meta
                    name="keywords"
                    content="web3 checkout buttons, crypto checkout buttons, cryptocurrency payment buttons, web3 payment integration, customizable payment buttons, xion global checkout buttons, crypto payment processing"
                  />
                </Helmet>
                {isDesktop && <CheckoutBD />}
                {isIpad && <CheckoutBI />}
                {isMobile && <CheckoutBM />}
              </>
            }
          />

          <Route
            path="/payment-links"
            element={
              <>
                <Helmet>
                  <title>Xion Global - Web3 Payment Links</title>
                  <meta
                    name="description"
                    content="Create and share instant Web3 payment links with Xion Global. Accept payments in multiple cryptocurrencies. Simplify your payment collection process."
                  />
                  <meta
                    name="keywords"
                    content="web3 payment links, crypto payment links, cryptocurrency payments, instant payment links, web3 payment collection, crypto payment processing, xion global payment links"
                  />
                </Helmet>
                {isDesktop && <PaymentLD />}
                {isIpad && <PaymentLI />}
                {isMobile && <PaymentLM />}
              </>
            }
          />

          <Route
            path="/about-us"
            element={
              <>
                <Helmet>
                  <title>Xion Global - About Us</title>
                  <meta
                    name="description"
                    content="Discover the visionaries behind Xion Global and the mission to revolutionize the decentralized payments landscape."
                  />
                  <meta
                    name="keywords"
                    content="xion global about us, xion global vision, xion global partners, about xion global"
                  />
                </Helmet>
                {isDesktop && <AUD />}
                {isIpad && <AUI />}
                {isMobile && <AUM />}
              </>
            }
          />

          <Route
            path="/privacy-terms"
            element={
              <>
                <Helmet>
                  <title>Xion Global - Privacy & Terms </title>
                  <meta
                    name="description"
                    content="Understand Xion Global's commitment to safeguarding your data and the terms that govern our services, ensuring a transparent and secure environment for businesses."
                  />
                  <meta
                    name="keywords"
                    content="xion global privacy & terms, xion global terms of service, xion global data protection, xion global know your customer security"
                  />
                </Helmet>
                {isDesktop && <LegalD />}
                {isIpad && <LegalI />}
                {isMobile && <LegalM />}
              </>
            }
          />

          <Route
            path="/contact-us"
            element={
              <>
                <Helmet>
                  <title>Xion Global - Contact Us</title>
                  <meta
                    name="description"
                    content="Reach out to the team for any queries, partnerships or to learn how Xion Global can elevate your business in the Web3 decentralized payment domain."
                  />
                  <meta
                    name="keywords"
                    content="xion global contact us, xion global payment enquiries, xion global pocs, xion global partnerships, merchant collaborations, xion global support"
                  />
                </Helmet>
                {isDesktop && <CUD />}
                {isIpad && <CUI />}
                {isMobile && <CUM />}
              </>
            }
          />
          <Route
            path="/news"
            element={
              <>
                <Helmet>
                  <title>
                    Xion Global News - Latest Updates on Web3 and Crypto
                    Payments
                  </title>
                  <meta
                    name="description"
                    content="Explore the latest updates and insights from Xion Global. Stay informed about the advancements in Web3, blockchain technology, and cryptocurrency payments."
                  />
                  <meta
                    name="keywords"
                    content="Xion Global news, Web3 updates, blockchain news, cryptocurrency payments, crypto news, digital payments, fintech innovations, Xion Global updates"
                  />
                </Helmet>

                {isDesktop && <NewsD />}
                {isIpad && <NewsI />}
                {isMobile && <NewsM />}
              </>
            }
          />

          <Route
            path="/refer-and-earn"
            element={
              <>
                <Helmet>
                  <title>Xion Global - Refer and Earn</title>
                  <meta
                    name="description"
                    content="Refer and Earn USDT to spend nationwide. Powered by Xion Global"
                  />
                  <meta
                    name="keywords"
                    content="xion refer, crypto referral program, usdt referral program, refer and earn usdt, refer and earn, xion referral program"
                  />
                </Helmet>

                {isDesktop && <ReferralD />}
                {isIpad && <ReferralI />}
                {isMobile && <ReferralM />}
              </>
            }
          />

          <Route
            path="/pay-with-crypto"
            element={
              <>
                <Helmet>
                  <title>Xion Global - Pay With Crypto</title>
                  <meta
                    name="description"
                    content="Simplify & save by paying with crypto. Pay With Crypto at all supported Xion Global partners. Quick, secure, and hassle-free Web3 crypto payments at your fingertips."
                  />
                  <meta
                    name="keywords"
                    content="pay with crypto, scan to pay crypto, tether payments, usdt crypto transactions, crypto payment solutions, blockchain payment gateway, cryptocurrency checkout, web3 crypto integration, digital wallet crypto payments, secure blockchain transactions"
                  />
                </Helmet>

                {isDesktop && <PWCD />}
                {isIpad && <PWCI />}
                {isMobile && <PWCM />}
              </>
            }
          />

          <Route
            path="/xion-global-july-2023-update-web3-payments-adoption"
            element={
              <>
                <Helmet>
                  <title>
                    Xion Global - July 2023 Update: Advancing Global Web3
                    Payments Adoption
                  </title>
                  <meta
                    name="description"
                    content="Discover how Xion Global is revolutionizing Web3 payments with innovative solutions, partnerships, and the launch of Xion Pay. Explore our achievements and vision for global adoption."
                  />
                  <meta
                    name="keywords"
                    content="Web3 payments, Xion Global update, Xion Pay launch, blockchain payments, crypto payment solutions, decentralized transactions, fintech innovations"
                  />
                  <meta
                    property="og:title"
                    content="Xion Global - July 2023 Update: Advancing Global Web3 Payments Adoption"
                  />
                  <meta
                    property="og:description"
                    content="Discover how Xion Global is revolutionizing Web3 payments with innovative solutions, partnerships, and the launch of Xion Pay. Explore our achievements and vision for global adoption."
                  />
                  <meta
                    property="og:image"
                    content="https://www.xion.global/img/articlecover1.png"
                  />
                  <meta
                    property="og:url"
                    content="https://www.xion.global/xion-global-july-2023-update-web3-payments-adoption"
                  />
                  <meta property="og:type" content="article" />
                </Helmet>

                {isDesktop && <Article1 />}
                {isIpad && <Article1 />}
                {isMobile && <Article1 />}
              </>
            }
          />
          <Route
            path="/scan-to-pay-crypto-xion-global-ukheshe"
            element={
              <>
                <Helmet>
                  <title>
                    Xion Global & Ukheshe - Introducing Crypto Payments in South
                    Africa
                  </title>
                  <meta
                    name="description"
                    content="Xion Global partners with Ukheshe to enable crypto payments via the Scan to Pay app. Experience seamless transactions with cryptocurrencies at over 600,000 vendors in South Africa."
                  />
                  <meta
                    name="keywords"
                    content="Scan to Pay crypto, Ukheshe crypto payments, South Africa crypto adoption, QR code payments, Web3 transactions, cryptocurrency checkout"
                  />
                  <meta
                    property="og:title"
                    content="Xion Global & Ukheshe - Introducing Crypto Payments in South Africa"
                  />
                  <meta
                    property="og:description"
                    content="Xion Global partners with Ukheshe to enable crypto payments via the Scan to Pay app. Experience seamless transactions with cryptocurrencies at over 600,000 vendors in South Africa."
                  />
                  <meta
                    property="og:image"
                    content="https://www.xion.global/img/articlecover5.png"
                  />
                  <meta
                    property="og:url"
                    content="https://www.xion.global/scan-to-pay-crypto-xion-global-ukheshe"
                  />
                  <meta property="og:type" content="article" />
                </Helmet>

                {isDesktop && <Article2 />}
                {isIpad && <Article2 />}
                {isMobile && <Article2 />}
              </>
            }
          />
          <Route
            path="/metamask-xion-global-1-click-web3-payments"
            element={
              <>
                <Helmet>
                  <title>
                    MetaMask & Xion Global - Pioneering 1-Click Web3 Payments in
                    South Africa
                  </title>
                  <meta
                    name="description"
                    content="Learn how MetaMask and Xion Global are simplifying Web3 payments with 1-click solutions. Explore the integration with Scan to Pay and the benefits of seamless crypto transactions."
                  />
                  <meta
                    name="keywords"
                    content="MetaMask integration, 1-click payments, Xion Global, Web3 transactions, Scan to Pay, crypto payments South Africa, decentralized finance"
                  />
                  <meta
                    property="og:title"
                    content="MetaMask & Xion Global - Pioneering 1-Click Web3 Payments in South Africa"
                  />
                  <meta
                    property="og:description"
                    content="Learn how MetaMask and Xion Global are simplifying Web3 payments with 1-click solutions. Explore the integration with Scan to Pay and the benefits of seamless crypto transactions."
                  />
                  <meta
                    property="og:image"
                    content="https://www.xion.global/img/articlecover2.png"
                  />
                  <meta
                    property="og:url"
                    content="https://www.xion.global/metamask-xion-global-1-click-web3-payments"
                  />
                  <meta property="og:type" content="article" />
                </Helmet>

                {isDesktop && <Article3 />}
                {isIpad && <Article3 />}
                {isMobile && <Article3 />}
              </>
            }
          />
          <Route
            path="/xion-global-gd-filia-blockchain-traditional-finance"
            element={
              <>
                <Helmet>
                  <title>
                    Xion Global & G+D - Bridging Blockchain with Traditional
                    Finance
                  </title>
                  <meta
                    name="description"
                    content="Xion Global collaborates with G+D to create a seamless financial ecosystem. Discover how their Filia solution integrates blockchain and traditional systems for efficient transactions."
                  />
                  <meta
                    name="keywords"
                    content="blockchain finance, G+D Filia, Xion Global integration, digital asset transactions, Web3 and traditional finance, decentralized payments"
                  />
                  <meta
                    property="og:title"
                    content="Xion Global & G+D - Bridging Blockchain with Traditional Finance"
                  />
                  <meta
                    property="og:description"
                    content="Xion Global collaborates with G+D to create a seamless financial ecosystem. Discover how their Filia solution integrates blockchain and traditional systems for efficient transactions."
                  />
                  <meta
                    property="og:image"
                    content="https://www.xion.global/img/articlecover4.png"
                  />
                  <meta
                    property="og:url"
                    content="https://www.xion.global/xion-global-gd-filia-blockchain-traditional-finance"
                  />
                  <meta property="og:type" content="article" />
                </Helmet>

                {isDesktop && <Article4 />}
                {isIpad && <Article4 />}
                {isMobile && <Article4 />}
              </>
            }
          />
          <Route
            path="/xion-global-2023-achievements-web3-payments"
            element={
              <>
                <Helmet>
                  <title>
                    2023 Highlights: Xion Global's Achievements in Web3 Payments
                  </title>
                  <meta
                    name="description"
                    content="Reflect on Xion Global's groundbreaking advancements in Web3 payments throughout 2023. From new partnerships to innovative payment solutions, explore our journey and future vision."
                  />
                  <meta
                    name="keywords"
                    content="Xion Global 2023, Web3 payment innovations, blockchain partnerships, decentralized finance, fintech milestones, digital transactions"
                  />
                  <meta
                    property="og:title"
                    content="2023 Highlights: Xion Global's Achievements in Web3 Payments"
                  />
                  <meta
                    property="og:description"
                    content="Reflect on Xion Global's groundbreaking advancements in Web3 payments throughout 2023. From new partnerships to innovative payment solutions, explore our journey and future vision."
                  />
                  <meta
                    property="og:image"
                    content="https://www.xion.global/img/articlecover3.png"
                  />
                  <meta
                    property="og:url"
                    content="https://www.xion.global/xion-global-2023-achievements-web3-payments"
                  />
                  <meta property="og:type" content="article" />
                </Helmet>

                {isDesktop && <Article5 />}
                {isIpad && <Article5 />}
                {isMobile && <Article5 />}
              </>
            }
          />
          <Route
            path="/xion-global-axelar-squid-cross-chain-payments"
            element={
              <>
                <Helmet>
                  <title>
                    Xion Global, Axelar & Squid - Unifying Cross-Chain Crypto
                    Payments
                  </title>
                  <meta
                    name="description"
                    content="Discover the strategic integration between Xion Global, Axelar, and Squid, enabling secure cross-chain crypto payments. Explore the benefits of seamless multi-chain transactions."
                  />
                  <meta
                    name="keywords"
                    content="cross-chain payments, Axelar integration, Squid API, Xion Global, multi-chain crypto, blockchain interoperability, Web3 transactions"
                  />
                  <meta
                    property="og:title"
                    content="Xion Global, Axelar & Squid - Unifying Cross-Chain Crypto Payments"
                  />
                  <meta
                    property="og:description"
                    content="Discover the strategic integration between Xion Global, Axelar, and Squid, enabling secure cross-chain crypto payments. Explore the benefits of seamless multi-chain transactions."
                  />
                  <meta
                    property="og:image"
                    content="https://www.xion.global/img/xion-axelar-squid.png"
                  />
                  <meta
                    property="og:url"
                    content="https://www.xion.global/xion-global-axelar-squid-cross-chain-payments"
                  />
                  <meta property="og:type" content="article" />
                </Helmet>

                {isDesktop && <Article6 />}
                {isIpad && <Article6 />}
                {isMobile && <Article6 />}
              </>
            }
          />
          <Route
            path="/tether-xion-global-blockchain-education-south-africa"
            element={
              <>
                <Helmet>
                  <title>
                    Tether & Xion Global - Driving Blockchain Education in South
                    Africa
                  </title>
                  <meta
                    name="description"
                    content="Tether and Xion Global team up to promote blockchain education and crypto payments in South Africa. Learn about their initiatives to enhance financial inclusion and awareness."
                  />
                  <meta
                    name="keywords"
                    content="Tether collaboration, blockchain education, Xion Global, South Africa crypto, Web3 awareness, stablecoin payments, digital financial literacy"
                  />
                  <meta
                    property="og:title"
                    content="Tether & Xion Global - Driving Blockchain Education in South Africa"
                  />
                  <meta
                    property="og:description"
                    content="Tether and Xion Global team up to promote blockchain education and crypto payments in South Africa. Learn about their initiatives to enhance financial inclusion and awareness."
                  />
                  <meta
                    property="og:image"
                    content="https://www.xion.global/img/articlecover6.png"
                  />
                  <meta
                    property="og:url"
                    content="https://www.xion.global/tether-xion-global-blockchain-education-south-africa"
                  />
                  <meta property="og:type" content="article" />
                </Helmet>

                {isDesktop && <Article7 />}
                {isIpad && <Article7 />}
                {isMobile && <Article7 />}
              </>
            }
          />
          <Route
            path="/web3-payments-blockchain-financial-services-xion-global"
            element={
              <>
                <Helmet>
                  <title>
                    Xion Global - Web3 Payments Revolution in Financial Services
                  </title>
                  <meta
                    name="description"
                    content="Explore how Xion Global is transforming financial services with Web3 payments. Discover the advantages of blockchain technology and the future of decentralized transactions."
                  />
                  <meta
                    name="keywords"
                    content="Web3 payments, blockchain technology, Xion Global, decentralized finance, crypto transactions, fintech innovation, financial services transformation"
                  />
                  <meta
                    property="og:title"
                    content="Xion Global - Web3 Payments Revolution in Financial Services"
                  />
                  <meta
                    property="og:description"
                    content="Explore how Xion Global is transforming financial services with Web3 payments. Discover the advantages of blockchain technology and the future of decentralized transactions."
                  />
                  <meta
                    property="og:image"
                    content="https://www.xion.global/img/articlecover7.png"
                  />
                  <meta
                    property="og:url"
                    content="https://www.xion.global/web3-payments-blockchain-financial-services-xion-global"
                  />
                  <meta property="og:type" content="article" />
                </Helmet>

                {isDesktop && <Article8 />}
                {isIpad && <Article8 />}
                {isMobile && <Article8 />}
              </>
            }
          />
          <Route
            path="/xion-global-1-click-gasless-usdt-payments-solana"
            element={
              <>
                <Helmet>
                  <title>
                    Xion Global - 1-Click Gasless USDT Payments on Solana
                  </title>
                  <meta
                    name="description"
                    content="Discover Xion Global's 1-click gasless USDT payments solution on the Solana network. A groundbreaking approach for seamless crypto transactions."
                  />
                  <meta
                    name="keywords"
                    content="Xion Global, USDT payments, gasless payments, Solana network, 1-click payments, decentralized transactions, crypto payments"
                  />
                  <meta
                    property="og:title"
                    content="Xion Global - 1-Click Gasless USDT Payments on Solana"
                  />
                  <meta
                    property="og:description"
                    content="Discover Xion Global's 1-click gasless USDT payments solution on the Solana network. A groundbreaking approach for seamless crypto transactions."
                  />
                  <meta
                    property="og:image"
                    content="https://www.xion.global/img/articlecover7.png"
                  />
                  <meta
                    property="og:url"
                    content="https://www.xion.global/xion-global-1-click-gasless-usdt-payments-solana"
                  />
                  <meta property="og:type" content="article" />
                </Helmet>

                {isDesktop && <Article9 />}
                {isIpad && <Article9 />}
                {isMobile && <Article9 />}
              </>
            }
          />

          <Route
            path="/xion-global-blu-voucher-stablecoin-redemption"
            element={
              <>
                <Helmet>
                  <title>
                    Xion Global & Blu Voucher - Redeem Vouchers into Stablecoins
                  </title>
                  <meta
                    name="description"
                    content="Learn how Xion Global and Blu Voucher are enabling South Africans to redeem vouchers into USDT stablecoins for seamless digital payments across 800,000+ locations."
                  />
                  <meta
                    name="keywords"
                    content="Xion Global, Blu Voucher, stablecoin redemption, blockchain payments, South Africa crypto, voucher to USDT, decentralized finance, financial inclusion"
                  />
                  <meta
                    property="og:title"
                    content="Xion Global & Blu Voucher - Redeem Vouchers into Stablecoins"
                  />
                  <meta
                    property="og:description"
                    content="Learn how Xion Global and Blu Voucher are enabling South Africans to redeem vouchers into USDT stablecoins for seamless digital payments across 800,000+ locations."
                  />
                  <meta
                    property="og:image"
                    content="https://www.xion.global/img/articlecover10.png"
                  />
                  <meta
                    property="og:url"
                    content="https://www.xion.global/xion-global-blu-voucher-stablecoin-redemption"
                  />
                  <meta property="og:type" content="article" />
                </Helmet>

                {isDesktop && <Article10 />}
                {isIpad && <Article10 />}
                {isMobile && <Article10 />}
              </>
            }
          />

          <Route
            path="/*"
            element={
              <>
                <Helmet>
                  <title>Xion Global - 404 Page Error</title>
                  <meta
                    name="description"
                    content="Oops! The page you're looking for doesn't exist or might be broken. Navigate back or explore our main menu for more."
                  />
                  <meta
                    name="keywords"
                    content="404 page error, xion global error page"
                  />
                </Helmet>
                {isDesktop && <ErrorPageDesktop />}
                {isIpad && <ErrorPageIpad />}
                {isMobile && <ErrorPageMobile />}
              </>
            }
          />

          <Route
            path="*"
            element={
              <>
                <Helmet>
                  <title>Xion Global - 404 Page Error</title>
                  <meta
                    name="description"
                    content="Oops! The page you're looking for doesn't exist or might be broken. Navigate back or explore our main menu for more."
                  />
                  <meta
                    name="keywords"
                    content="404 page error, xion global error page"
                  />
                </Helmet>
                {isDesktop && <ErrorPageDesktop />}
                {isIpad && <ErrorPageIpad />}
                {isMobile && <ErrorPageMobile />}
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};
